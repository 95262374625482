<template>
  <Page :loading="loading">
    <Navbar title="查询结果" />
    <div class="pa-16 fleet-result-wrap">
      <div v-if="driverInfoRef.length > 0" class="pa-24 search-result-card d-flex flex-column">
        <div class="d-flex justify-center flex-column align-center" v-if="driverInfoRef[0].fleetInfoVO">
          <img :src="require('@/assets/fleetAvantar.png')" alt="" />
          <div class="text-center mt-12 font-16 fw-500">{{ driverInfoRef[0].fleetInfoVO.name }}</div>
          <div class="warning-tag font-12 text_warning lh-28 mt-8 px-24" v-if="isInFleet">
            您已在该车队下
          </div>
        </div>
        <div style="width: 100%">
          <div class="carrier-info font-14 px-16 mb-16 lh-40 d-flex justify-space-between">
            <div class="text_muted">
              <Icon name="wodechedui-hui" />
              <span class="inline-block ml-6">车队长:</span>
            </div>
            <span class="fw-500">{{ driverInfoRef[0].carrierName }}</span>
          </div>
          <div class="carrier-info font-14 px-16 mb-16 lh-40 d-flex justify-space-between">
            <div class="text_muted">
              <Icon name="dianhua" />
              <span class="inline-block ml-6">手机号:</span>
            </div>
            <span>
              <a :href="`tel:${driverInfoRef[0].carrierPhone}`">{{ driverInfoRef[0].carrierPhone }}</a>
            </span>
          </div>
        </div>
        <Button v-if="!isInFleet" @click="handleJoin" :loading="loading" size="large" type="primary">申请加入</Button>
        <Button v-else @click="handleJump" :loading="loading" size="large" type="primary">查看</Button>
      </div>
      <div v-else class="d-flex align-center justify-center" style="height: 100%">
        <Empty
          class="custom-image"
          :image="require('@/assets/FleetResultNoMessage.png')"
          description="未找到相关司机信息"
        />
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import { apiSearchCarrierByPhone, apiJoinFleet } from '@/api/carrier';
import { apiCheckInFleet } from '@/api/fleet';
import { useDriverFleetStore } from '@/store/fleet';
import { Button, Empty, Toast } from 'vant';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'driver-fleet',
  components: {
    Button,
    Empty,
    Toast
  },
  setup(_, ctx) {
    const driverInfoRef = ref([]);
    const useUserInfoStore = useUserStore();
    const useDriverFleetInfoStore = useDriverFleetStore();
    const isInFleet = ref(false);
    const loading = ref(false);

    onBeforeMount(async () => {
      // 获取司机数据
      const carRes = await apiSearchCarrierByPhone({ phone: ctx?.root?._route?.query?.phone }); // 先查询司机
      driverInfoRef.value = carRes.data;
      if (carRes.data[0]) {
        const { carrierId } = carRes.data[0];
        // 查询用户是否在司机的车队中
        const checkRes = await apiCheckInFleet({ carrierId, driverId: useUserInfoStore.driverId });
        isInFleet.value = checkRes.data;
      }
    });

    const handleJump = async () => {
      ctx.root.$router.push(PageEnum.DRIVER_FLEET);
    };
    const handleJoin = async () => {
      // 加入车队
      Toast.loading('正在加入车队...');
      loading.value = true;
      const { carrierId } = driverInfoRef.value[0];
      try {
        await apiJoinFleet({
          carrierId,
          driverId: useUserInfoStore.driverId
        });
        await useDriverFleetInfoStore.queryCarrier();
        Toast.clear();
        ctx.root.$router.push(PageEnum.DRIVER_FLEET);
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      isInFleet,
      driverInfoRef,
      handleJoin,
      handleJump
    };
  }
});
</script>
<style lang="less">
.fleet-result-wrap {
  height: calc(~'100vh - .44rem');
  background: #f1f3f5;
  .custom-image .van-empty__image {
    width: 0.32rem;
    height: 0.4rem;
  }
  .warning-tag {
    background: rgba(252, 148, 18, 0.1);
    border-radius: 4px;
  }
  .search-result-card {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 0.84rem;
      height: 0.84rem;
    }
    .carrier-info {
      width: 100%;
      background: rgba(188, 188, 188, 0.1);
      border-radius: 4px;
    }
  }
}
</style>
